import { Injectable, inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlerService } from '../services/error-handler.service';
import { environment } from 'src/environments/environments';
import { ValidationException } from '../models/validation-exception.model';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  private readonly _errorHandlerService = inject(ErrorHandlerService);
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      map(res => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        if (Array.isArray(error.error)) {
          const validationException = new ValidationException(
            error.status,
            error.statusText,
            error.error
          );

          throw validationException;
        }
        this._errorHandlerService.handleError(error);
        throw error;
      })
    );
  }
}
