import { Injectable, signal, computed } from '@angular/core';
import { RoutingHistoryItem } from '../types/routing-history-item.type';

@Injectable({
  providedIn: 'root',
})
export class RoutingHistoryService {
  private readonly _routingHistory = signal<RoutingHistoryItem[]>([]);

  public previousPageUrl = computed(() => {
    const history = this._routingHistory();
    return history.length > 1 ? history[history.length - 2].url : '/';
  });

  addRoutingHistory(history: RoutingHistoryItem[]): void {
    this._routingHistory.set(history);
  }
}
