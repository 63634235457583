import { Injectable, inject } from '@angular/core';
import { ThemeType } from '../enums/theme-type.enum';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private activeTheme!: ThemeType;
  private readonly _localStorageService = inject(LocalStorageService);

  setTheme(themeType: ThemeType) {
    this._localStorageService.setItem('THEME_KEY', themeType);
    document.documentElement.classList.add(themeType);
    switch (themeType) {
      case ThemeType.LIGHT:
        document.documentElement.classList.remove(ThemeType.DARK);
        break;
      case ThemeType.DARK:
        document.documentElement.classList.remove(ThemeType.LIGHT);
        break;
    }
  }
  removeThemeType(themeType: ThemeType) {
    this._localStorageService.removeItem(themeType);
    document.documentElement.classList.remove(themeType);
  }

  getThemeType() {
    if (this._localStorageService.getItem('THEME_KEY')) {
      return this._localStorageService.getItem('THEME_KEY') as ThemeType;
    }
    return ThemeType.LIGHT;
  }

  toggleTheme(): void {
    if (this.getThemeType() === ThemeType.LIGHT) {
      this.removeThemeType(ThemeType.LIGHT);
      this.setTheme(ThemeType.DARK);
    } else {
      this.removeThemeType(ThemeType.DARK);
      this.setTheme(ThemeType.LIGHT);
    }
  }
  onInitilize() {
    this.activeTheme = this.getThemeType();
    this.setTheme(this.activeTheme);
  }
}
