import {
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  PreloadAllModules,
  RouterModule,
  provideRouter,
  withComponentInputBinding,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { APP_ROUTES } from './routes/app.routes';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { TranslocoHttpLoader } from './providers/transloco.loader';
import { CaptchaModule } from './shared/modules/captcha/captcha.module';
import { provideCore } from './providers/core.provider';
import { TourConsoleModule } from 'ngx-ui-tour-console';
import { MessageService } from 'primeng/api';
import { provideEchartsCore } from 'ngx-echarts';
import * as echarts from 'echarts/core';

import { BarChart } from 'echarts/charts';
import { PieChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import { TitleComponent } from 'echarts/components';
import { TooltipComponent } from 'echarts/components';
import { LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
  BarChart,
  PieChart,
  GridComponent,
  CanvasRenderer,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
]);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      CaptchaModule,
      BrowserModule,
      RouterModule,
      TourConsoleModule
    ),
    provideAngularSvgIcon(),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    provideAnimationsAsync(),
    MessageService,
    provideCore(),
    provideRouter(
      APP_ROUTES,
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
      withViewTransitions(),
      withComponentInputBinding(),
      withPreloading(PreloadAllModules)
    ),
    provideTransloco({
      config: {
        availableLangs: ['en', 'tr'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),
    provideTranslocoLocale(),
    provideEchartsCore({ echarts }),
  ],
};
