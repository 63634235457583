import { Injectable, inject } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CaptchaService } from 'src/app/shared/modules/captcha/service/captcha.service';

@Injectable()
export class CapthcaInterceptorService implements HttpInterceptor {
  private readonly _captchaService = inject(CaptchaService);

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (req.method === 'GET') {
      return next.handle(req);
    }

    return this._captchaService.verify().pipe(
      switchMap(token => {
        if (!token) {
          return next.handle(req);
        }

        const clonedReq = req.clone({
          setHeaders: {
            'X-Captcha-Token': token,
          },
        });
        return next.handle(clonedReq);
      }),
      catchError((error: Error) => throwError(() => error))
    );
  }
}
