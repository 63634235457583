import { Injectable, inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environments';
import { LoaderService } from '../services/loader.service';
import { Guid } from 'guid-typescript';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private readonly _loaderService = inject(LoaderService);
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(environment.apiUrl))
      return next.handle(request);
    if (request.headers.has('ignoreLoading')) {
      request.headers.delete('ignoreLoading');
      return next.handle(request);
    }
    const requestId = Guid.create();
    request = request.clone({
      headers: request.headers.set('requestId', requestId.toString()),
    });
    this._loaderService.addRequest(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<unknown>) => {
        this._loaderService.completeRequest(request);
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this._loaderService.completeRequest(request);
        throw error;
      })
    );
  }
}
